import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
	  <div className="404-page" style={{padding: "30px"}}>
	    <SEO title="404: Not found" />
	    <h1>網頁未找到</h1>
    </div>
  </Layout>
)

export default NotFoundPage
